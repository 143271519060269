.header_song{
  display: flex;
  flex-direction: row;
}

.header_song-arrow{
  width: 30px;
  height: 30px;
  margin-top: 15px;
  margin-left: 3%;
}

.header_song-title{
  width: 46%;
  height: 20px;
  font-size: 1em;
  margin-top: 20px;
  font-weight: 600;
  margin-left: 8%;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

.header_song-music{
  width: 25px;
  height: 25px;
  margin-top: 14px;
  margin-left: 7%;
  border-bottom: 2px solid #121212;
}

.header_song-accordes{
  width: 15px;
  color:#121212;
  font-size: 1.3em;
  font-weight: 900;
  align-self: center;
  margin-left: 7%;
  border-bottom: 2px solid #eee;
}

.hideAccordes{
  border-bottom: 2px solid #121212;
}

.modal_accord{
  display: flex;
  align-self: center;
  justify-content: center;
  width: 30%;
  height: 30px;
  position: absolute;
  top: 45px;
  right: 80px;
  color:#121212;
  background-color: #fff;
  z-index: 2;
  border-radius: 5px;
  font-size: 1.6em;
  font-weight: 900;
}

.none{
  display: none;
}

#none{
  display: none;
}

.main_song{
  display: flex;
  flex-direction: column;
  color:#121212;
  background-color: #eee;
  padding-right: 6%;
  padding-left: 2%;
  font-size: 1.1em;
  font-weight: 700;
}

.songDark{
  color:#eee;
  background-color: #121212;
}

.main_song-couplet{
  font-size: 1em;
  color:grey;
  font-weight: 700;
}

.main-song-accord{
  font-size: 1em;
  font-weight: 400;
}

.accDark{
  color: #00dd00;
}